import th from '@tillhub/javascript-sdk'

export function genInitialData() {
  return {
    purchaseOrderNumber: '',
    notes: '',
    recipients: [],
    businessPartner: null,
    products: [],
    location: null
  }
}

export function statusOptions(vm = { $t: (f) => f }) {
  return {
    draft: {
      type: 'primary',
      value: 'draft',
      label: vm.$t('common.resource.purchase_order.filters.status.draft')
    },
    sent: {
      type: 'warning',
      value: 'sent',
      label: vm.$t('common.resource.purchase_order.filters.status.sent')
    },
    done: {
      type: 'success',
      value: 'done',
      label: vm.$t('common.resource.purchase_order.filters.status.done')
    }
  }
}

export async function downloadPurchaseOrderPDF({ id, purchaseOrderNumber }) {
  try {
    const { data, contentType, filename } = await th.purchaseOrders().pdfUri(id)

    // Create a link element
    const link = document.createElement('a')
    link.href = `data:${contentType};base64,${data}`
    link.download = filename
    // Trigger the download
    link.click()
  } catch (err) {
    return err
  }
}

import { defineStore } from 'pinia'
import th from '@tillhub/javascript-sdk'

export const usePurchaseOrdersStore = defineStore('purchaseOrders', {
  state: () => ({ purchaseOrdersCount: 1, purchaseOrderProposal: null }),
  getters: {
    purchaseOrdersExists: (state) => state.purchaseOrdersCount > 0
  },
  actions: {
    async checkPurchaseOrdersCount() {
      try {
        const { data } = await th
          .purchaseOrders()
          .meta({ query: { deleted: false } })
        this.purchaseOrdersCount = data?.count || 0
      } catch (err) {
        this.$logException(err, { trackError: false })
        this.purchaseOrdersCount = 0
      }
    },

    setPurchaseOrderProposal({ products, supplierId }) {
      this.purchaseOrderProposal = {
        products,
        supplierId
      }
    },

    resetPurchaseOrderProposal() {
      this.purchaseOrderProposal = null
    }
  }
})

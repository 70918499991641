<template>
  <th-modal
    v-loading="isLoading"
    show-inline
    name="email-preview"
    width="1200px"
    height="800px"
    :title="$t('common.resource.purchase_order.singular')"
    body-class="flex flex-col"
    @close="handleCancel"
  >
    <!-- Emails -->
    <el-form ref="form" model="form" :rules="rules" class="w-1/2">
      <el-form-item
        :label="$t('common.forms.labels.sender_email')"
        prop="replyToEmail"
      >
        <el-input
          id="replyToEmail"
          v-model="form.replyToEmail"
          type="email"
          :placeholder="$t('common.forms.labels.sender_email')"
        />
      </el-form-item>
      <el-form-item
        :label="$t('common.forms.labels.recipient_email')"
        prop="recipients"
      >
        <el-select
          id="recipients"
          v-model="form.recipients"
          v-cancel-read-only
          clearable
          multiple
          filterable
          allow-create
          class="w-full"
          @clear="form.recipients = []"
        >
          <el-option
            v-for="email in emails"
            :key="email"
            :label="email"
            :value="email"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <div class="p-10">
      <!-- Email Subject -->
      <h1 class="pb-10">{{ subject }}</h1>
      <!-- Email Body -->
      <p v-for="line in body" :key="line">{{ line }}</p>
      <div class="pt-10 cursor-pointer">
        <!-- Download PDF button -->
        <a class="el-button--text-icon" @click.stop="handleDownloadPDF()">
          <el-icon class="text-5xl">
            <Document />
          </el-icon>
        </a>
      </div>
    </div>
    <template #footer>
      <el-button @click="handleCancel">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>
      <el-button type="primary" @click="handleSave">
        {{ $t('common.interactions.buttons.send') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { downloadPurchaseOrderPDF } from '../helpers'

export default {
  props: {
    selectedProductOrderId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      form: {
        recipients: [],
        replyToEmail: ''
      },
      emails: [],
      isLoading: false,
      purchaseOrderId: null,
      subject: '',
      body: []
    }
  },
  computed: {
    rules() {
      return {
        recipients: [{ validator: this.validateEmails }],
        replyToEmail: [{ validator: this.validateReplyToEmail }]
      }
    },
    emailRegex() {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    }
  },
  mounted() {
    this.$emitter.on('email-preview', this.fetchData)
  },
  beforeUnmount() {
    this.$emitter.off('email-preview')
  },
  methods: {
    async fetchData({ id, businessPartner }) {
      try {
        this.isLoading = true
        this.$thModal.show('email-preview')
        const { data: supplier } = await th.suppliers().get(businessPartner.id)
        const { data: email } = await th.purchaseOrders().preview(id)
        this.purchaseOrderId = id
        this.emails = supplier.email ? [supplier.email] : []
        this.form.recipients = [...this.emails]
        this.form.replyToEmail = email.replyToEmail
        this.subject = email?.subject
        this.body = email?.body.split('\n')
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.purchase_order.plural')
          })
        })
      } finally {
        this.isLoading = false
      }
    },
    async handleDownloadPDF() {
      const error = await downloadPurchaseOrderPDF({ id: this.purchaseOrderId })
      if (error) {
        this.$logException(error, {
          message: this.$t('notifications.pdf.download.fail')
        })
      }
    },
    handleCancel() {
      this.$thModal.hide('email-preview')
      this.$emit('close')
    },
    handleSave() {
      this.validate(async (valid) => {
        if (!valid) return
        try {
          this.isLoading = true
          await th.purchaseOrders().send(this.purchaseOrderId, {
            recipients: this.form.recipients,
            ...(this.form.replyToEmail
              ? { replyToEmail: this.form.replyToEmail }
              : {})
          })
          this.$emitter.emit('refresh-requested')
          this.$thModal.hide('email-preview')
          this.$emit('close')
        } catch (err) {
          this.$logException(err, {
            message: this.$t('common.error.action.update.single', {
              resource: this.$t('common.resource.purchase_order.plural')
            })
          })
        } finally {
          this.isLoading = false
        }
      })
    },
    validate(cb) {
      this.$refs['form'].validate(cb)
    },
    validateEmails(rule, value, callback) {
      //check if array is empty
      if (this.form.recipients.length === 0) {
        return callback(
          new Error(
            this.$t(
              'pages.purchase_orders.modals.email.validation.errors.required'
            )
          )
        )
      }
      //check if array contains only valid emails
      const invalidEmails = this.form.recipients.filter((email) => {
        return !this.emailRegex.test(email)
      })

      if (invalidEmails.length > 0) {
        return callback(
          new Error(
            this.$t(
              'pages.purchase_orders.modals.email.validation.warnings.required'
            )
          )
        )
      }

      callback()
    },
    validateReplyToEmail(rule, value, callback) {
      if (!this.form.replyToEmail) {
        callback()
      }

      const invalidReplyToEmail = !this.emailRegex.test(this.form.replyToEmail)

      if (invalidReplyToEmail) {
        return callback(
          new Error(
            this.$t(
              'pages.purchase_orders.modals.email.validation.warnings.required'
            )
          )
        )
      }

      callback()
    }
  }
}
</script>
